/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.center-form {
    width: 290px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    label {
        background-color: rgba(255, 255, 255, 0.7);
        border-color: rgba(255, 255, 255, 0);

        &.top-input {
            border-radius: 4px 4px 0px 0px !important;
        }

        &.bottom-input {
            border-radius: 0px 0px 4px 4px !important;
        }
    }

    .button-positive {
        opacity: 0.9;
    }
}

.alert-wrapper{
    max-width: unset !important;
    min-width: 40% !important;
}

ion-content {
    --background: url(./assets/LoginBackground.png) 0 0/100% 100% no-repeat;
    background-position: fixed;
    background-repeat: no-repeat;
    background-size: cover;
}

.padding {
    padding: 5px
}

.margin {
    margin: 10px;
}

ion-title {
    font-weight: bold;
}

.ion-valid {
    caret-color: var(--ion-color-primary) !important;
    --highlight-background: var(--ion-color-primary) !important;
}